.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;
  background: white;

  width: 1280px;

  h1 {
    margin-top: 0;
    font-size: 45px;
  }
}

@media only screen and (max-width: 1280px) {
  .App {
    width: 100vw;

    h1 {
      font-size: 35px;
    }
  }
}
