body {
  display: flex;
  justify-content: center;

  margin: 0;
  color: #333;
  background: #fafbfc;
  font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet,', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
